import 'twin.macro';
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import { Container, Heading } from '../../components/atoms';
import { PortableText } from '../../components/elements';

const Wrapper = styled.div`
  svg {
    max-width: 4.25rem !important;
  }
`;

export const paymentFailQuery = graphql`
  query paymentFailQuery {
    sanityPayment(slug: { current: { eq: "failed" } }) {
      _id
      title
      slug {
        current
      }
      body
      buttonText
      buttonLink
    }
  }
`;

const PaymentFailurePage = ({ data }) => {
  if (!data) return null;
  const page = data?.sanityPayment;
  return (
    <Layout>
      <Container>
        <Wrapper tw="text-center mt-6">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            tw="mt-6 mb-3 mx-auto"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm52.7 283.3L256 278.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3l52.7-52.7-52.7-52.7c-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3 6.2-6.2 16.4-6.2 22.6 0l52.7 52.7 52.7-52.7c6.2-6.2 16.4-6.2 22.6 0 6.2 6.2 6.2 16.4 0 22.6L278.6 256l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6-6.2 6.3-16.4 6.3-22.6 0z" />
          </svg>
          <div className="pb-6">
            {page?.title && <Heading>{page?.title}</Heading>}
            {page?.body && (
              <PortableText tw="!px-0 !py-1">{page.body}</PortableText>
            )}
          </div>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default PaymentFailurePage;
